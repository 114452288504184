<template>
  <div class="page-banners-add">
    <pro-form
      label-width="120px"
      label-suffix=":"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitButtonProps: {
          loading
        },
        submitText: '保存',
        resetText: '返回'
      }"
      @submit="submit"
      @reset="reset"
    ></pro-form>
  </div>
</template>

<script>
import { uid } from 'uid'
import Upload from '@/components/upload'
import { localStore } from '@/utils/store'
import * as ConstantRouter from '@/constant/router'

export default {
  data() {
    return {
      loading: false,
      id: this.$route.params.id,
      jsList: [],
      jsLoading: false,
      model: {
        title: '',
        titleEn: '',
        jsId: undefined,
        imgUrlFile: null,
        imgUrlEnFile: null,
        type: 0, // 0 不跳转 1 跳转文章 3 自定义url
        directUrl: '' // 跳转链接或文章id
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '标题(中文)',
            prop: 'title'
          },
          style: {
            width: '360px'
          }
        },
        {
          type: 'input',
          formItemProps: {
            label: '标题(英文)',
            prop: 'titleEn'
          },
          style: {
            width: '360px'
          }
        },
        {
          formItemProps: {
            style: 'width: 460px',
            label: '选择竞赛',
            prop: 'jsId'
          },
          render: (h, prop, field, model) => {
            return (
              <el-select
                style="width: 360px;"
                v-model={model[prop]}
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                remoteMethod={this.fetchJsList}
                onFocus={this.fetchJsListFocus}
                loading={this.jsLoading}
              >
                {this.jsList.map((item) => (
                  <el-option
                    key={item.value}
                    label={item.label}
                    value={item.value}
                  ></el-option>
                ))}
              </el-select>
            )
          }
        },
        {
          formItemProps: {
            label: '图片(中文)',
            prop: 'imgUrlFile'
          },
          render(h, prop, field, model) {
            return (
              <Upload
                v-model={model[prop]}
                token={localStore.get('dashboard-token')}
              ></Upload>
            )
          }
        },
        {
          formItemProps: {
            label: '图片(英文)',
            prop: 'imgUrlEnFile'
          },
          render(h, prop, field, model) {
            return (
              <Upload
                v-model={model[prop]}
                token={localStore.get('dashboard-token')}
              ></Upload>
            )
          }
        },
        {
          type: 'radio-group',
          formItemProps: {
            label: '跳转类型',
            prop: 'type'
          },
          children: [
            {
              label: 0,
              text: '不跳转'
            },
            {
              label: 1,
              text: '跳转文章'
            },
            {
              label: 2,
              text: '自定义url'
            }
          ]
        },
        {
          type: 'input',
          formItemProps: {
            label: '跳转链接',
            prop: 'directUrl'
          },
          style: {
            width: '360px'
          }
        }
      ],
      rules: {
        title: [
          { required: true, message: '请输入标题(中文)', trigger: 'change' }
        ],
        titleEn: [
          { required: true, message: '请输入标题(英文)', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {
    if (this.id) this.fetchDetail()
  },
  methods: {
    async fetchJsList(keyword) {
      this.jsLoading = true
      const [err, res] = await this.$serve.jsRecord.getPageList({
        data: {
          pageSize: 100,
          keyword
        }
      })
      this.jsLoading = false
      if (err) return
      this.jsList = res.records.map((item) => ({
        label: item.name,
        value: item.id
      }))
    },
    async fetchJsListFocus() {
      if (!this.jsList.length) {
        this.fetchJsList('')
      }
    },
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.jsBanner.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.model = {
        title: res.title,
        titleEn: res.titleEn,
        jsId: res.jsId,
        imgUrlFile: res.imgUrl
          ? {
              uid: uid(),
              status: 'success',
              url: res.imgUrl
            }
          : null,
        imgUrlEnFile: res.imgUrlEn
          ? {
              uid: uid(),
              status: 'success',
              url: res.imgUrlEn
            }
          : null,
        type: res.type,
        directUrl: res.directUrl
      }
      if (res.jsId) {
        this.jsList = [
          {
            label: res.jsName,
            value: res.jsId,
          }
        ]
      }
    },
    submit({ imgUrlFile, imgUrlEnFile, ...data }) {
      const params = {
        ...data
      }
      if (imgUrlFile) {
        params.imgUrl = imgUrlFile.response?.data || imgUrlFile?.url
      }
      if (imgUrlEnFile) {
        params.imgUrlEn = imgUrlEnFile.response?.data || imgUrlEnFile?.url
      }
      if (this.id) {
        this.update(params)
      } else {
        this.add(params)
      }
    },
    reset() {
      this.$router.push({
        name: ConstantRouter.DASHBOARD_BANNERS
      })
    },
    async add(data) {
      this.loading = true
      const [err] = await this.$serve.jsBanner.add({
        data
      })
      this.loading = false
      if (err) return
      this.$message.success('轮播图添加成功')
      this.$router.push({
        name: ConstantRouter.DASHBOARD_BANNERS
      })
    },
    async update(data) {
      this.loading = true
      const [err] = await this.$serve.jsBanner.update({
        data: {
          ...data,
          id: this.id
        }
      })
      this.loading = false
      if (err) return
      this.$message.success('轮播图修改成功')
      this.$router.push({
        name: ConstantRouter.DASHBOARD_BANNERS
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
